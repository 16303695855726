import axios from '@/common/axios'
import qs from 'qs'

export function addConfig (data) {
  return axios({
    method: 'post',
    url: '/config/add',
    data: qs.stringify(data)
  })
}

export function deleteConfig (id) {
  return axios({
    method: 'delete',
    url: '/config/delete/' + id
  })
}

export function updateConfig (data) {
  return axios({
    method: 'put',
    url: '/config/update',
    data: qs.stringify(data)
  })
}

export function selectConfigInfo (id) {
  return axios({
    method: 'get',
    url: '/config/info/' + id
  })
}

export function selectConfigList (query) {
  return axios({
    method: 'get',
    url: '/config/list',
    params: query
  })
}
