<template>
  <div id="config">
    <el-dialog
      :title="configFormTitle"
      width="500px"
      :visible.sync="configDialogVisible"
      :close-on-click-modal="false"
      @close="configDialogClose"
    >
      <el-form
        ref="configFormRef"
        :model="configForm"
        :rules="configFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="编号" prop="code">
              <el-input v-model="configForm.code" placeholder="请输入编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="标签" prop="label">
              <el-input v-model="configForm.label" placeholder="请输入标签" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="数据" prop="data">
              <el-input
                v-model="configForm.data"
                placeholder="请输入数据"
                type="textarea"
                :rows="8"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="configDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="configFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input v-model="searchForm.code" placeholder="请输入编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="configPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="code" label="编号" width="80" />
      <el-table-column prop="label" label="标签" width="120" />
      <el-table-column prop="data" label="数据" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="configPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addConfig,
  deleteConfig,
  updateConfig,
  selectConfigInfo,
  selectConfigList
} from '@/api/system/config'

export default {
  data () {
    return {
      configDialogVisible: false,
      configFormTitle: '',
      configForm: {
        id: '',
        code: '',
        label: '',
        data: ''
      },
      configFormRules: {
        code: [{ required: true, message: '编号不能为空', trigger: ['blur', 'change']}],
        label: [{ required: true, message: '标签不能为空', trigger: ['blur', 'change']}],
        data: [{ required: true, message: '数据不能为空', trigger: ['blur', 'change']}]
      },
      configPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        code: ''
      }
    }
  },
  created () {
    selectConfigList(this.searchForm).then(res => {
      this.configPage = res
    })
  },
  methods: {
    configDialogClose () {
      this.$refs.configFormRef.resetFields()
    },
    configFormSubmit () {
      if (this.configFormTitle === '配置详情') {
        this.configDialogVisible = false
        return
      }
      this.$refs.configFormRef.validate(async valid => {
        if (valid) {
          if (this.configFormTitle === '新增配置') {
            this.configForm.id = ''
            await addConfig(this.configForm)
          } else if (this.configFormTitle === '修改配置') {
            await updateConfig(this.configForm)
          }
          this.configPage = await selectConfigList(this.searchForm)
          this.configDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.configFormTitle = '新增配置'
      this.configDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteConfig(row.id)
        if (this.configPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.configPage = await selectConfigList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.configFormTitle = '修改配置'
      this.configDialogVisible = true
      this.selectConfigInfoById(row.id)
    },
    handleInfo (index, row) {
      this.configFormTitle = '配置详情'
      this.configDialogVisible = true
      this.selectConfigInfoById(row.id)
    },
    selectConfigInfoById (id) {
      selectConfigInfo(id).then(res => {
        this.configForm.id = res.id
        this.configForm.code = res.code
        this.configForm.label = res.label
        this.configForm.data = res.data
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectConfigList(this.searchForm).then(res => {
        this.configPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectConfigList(this.searchForm).then(res => {
        this.configPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectConfigList(this.searchForm).then(res => {
        this.configPage = res
      })
    }
  }
}
</script>

<style>
#config .el-table .cell {
  white-space: pre-line;
}
</style>
